<script>
import "./plugins/select2.css"
import "./plugins/select2.min.js"
import Gen from '../../helper/Gen';

var $ = window.$;

export default {
  name: "Select2",
  props: {
    url: String,
    options:[Array, Object],
    value: [String, Number, Array, Object],
    object:Array,
    selectClass:String,
    multiple:Boolean,
    config: Object,
  },
  data(){
    return {
      data: false,
    }
  },
  computed:{
    page(){ return this.$root.page },
    datas(){
      var options = this.options || []
      if(this.data) return this.data
      return options
    },
    pluginOptions(){
      var options = Object.assign({
        // dropdownCssClass: this.selectClass,
        // containerCssClass: this.selectClass,
        // allowClear: false,
        escapeMarkup: function(m) { return m; }
      },this.config)
      if(options.formatResult){
        options.data = {results: this.datas, text: options.text}
        if(!options.formatSelection) options.formatSelection = options.formatResult
      }
      return options
    },
    selObj(){
      return this.datas.filter((v,k)=>{ return this.optKey(v) == this.value })[0]
    }
  },
  mounted: function () {
    global.select2 = (this)
    this.getApi()
    $(this.$el)
      .select2(this.pluginOptions)
      .val(this.value)
      .trigger('change')
      // emit event on change.
      .on('change', (resp) => {
        resp.val = resp.currentTarget.value;
        if(this.multiple) resp.val = $(this.$el).val()

        this.$emit('change', resp)
        this.$emit('input', resp.val);
        this.$emit("selObj", this.selObj)
        if($(this.$el).closest("form").length) $(this.$el).valid();
      });
  },
  methods:{
    getApi(){
      if(!this.url) return
      Gen.apirest(this.url, {}, (err, resp)=>{
        if (err) console.log(err)
        this.data = resp
        setTimeout(()=>{
          if(!this.notSelected(resp)){
            this.$emit("input", this.$slots.default ? "" : this.optKey(resp[0]))
          }
          $(this.$el).val(this.value).select2(this.pluginOptions)
        }, 50)
      })
    },
    optKey: function(v){
      return this.object?v[this.object[0]]:v
    },
    optValue: function(v){
      if(this.object){
        if(typeof this.object[1] == "function"){
          return this.object[1](v);
        }
        var obj = this.object[1].split("+");
        if(obj[1]) return v[obj[0]]+" "+v[obj[1]];
      }
      return this.object?v[this.object[1]]:v
    },
    notSelected(data){
      var obj = data.filter((v,k)=>{ return (this.object?v[this.object[0]]:k) == this.value })[0];
      return obj ? true : false
    }
  },
  watch: {
    value(value) {
      $(this.$el).val(value).select2(this.pluginOptions)
      this.$emit("selObj", this.selObj)
    },
    url(){
      this.getApi()
    },
    options: function(){
      setTimeout(()=>{
        $(this.$el).val(this.value).select2(this.pluginOptions)
      },10)
    }
  },
  destroyed() {
    $(this.$el).off().select2('destroy')
  }
}
</script>

<template>
  <input v-if="pluginOptions.formatResult"/>
  <select v-else :multiple="multiple">
    <slot></slot>
    <option v-for="(v,k) in datas" :value="object?v[object[0]]:k" :key="k">{{optValue(v)}}</option>
  </select>
</template>